<template>
    <div class="login">
        <img class="bg-img bg-img-show" src="../assets/lang.png" alt="">

        <div class="login-box" v-loading="loading">
            <!-- <img class="logo" src="../assets/logo-pure.png" alt=""> -->

            <el-form ref="form" :rules="formRule" :model="form" label-width="80px">
                <el-form-item label="账号" prop="account">
                    <el-input v-model="form.account" size="medium"></el-input>
                </el-form-item>

                <el-form-item label="密码" prop="password">
                    <el-input v-model="form.password" type="password" size="medium"></el-input>
                </el-form-item>

                <el-form-item label="验证码" prop="captcha">
                    <el-input v-model="form.captcha" size="medium" maxlength="4" style="width: 50%" @keyup.enter.native="submit"></el-input>
                    <div class="captcha" v-html="'<div class=\'captcha-refresh\'>点击刷新</div>' + captchaImg" @click="loadCaptcha"></div>
                </el-form-item>

                <p class="submit">
                    <el-button type="primary" @click="submit" size="medium">立即登录</el-button>
                </p>
            </el-form>

        </div>
    </div>
</template>

<script>
    import md5 from 'js-md5'
    export default {
        data() {
            return {
                form: {
                    account: '',
                    password: '',
                    captcha: '',
                },
                loading: false,
                captchaImg: '',
                sendCooling: '发送',
                formRule: {
                    account: [{
                        required: true,
                        message: '请填写账号',
                        trigger: 'blur'
                    }],
                    password: [{
                        required: true,
                        message: '请填写密码',
                        trigger: 'blur'
                    }],
                    captcha: [{
                        required: true,
                        message: '请填写验证码',
                        trigger: 'blur'
                    }],
                }
            }
        },
        created() {
            this.loadCaptcha()
        },
        mounted() {},
        methods: {
            // 更新验证码
            loadCaptcha() {
                let that = this
                that.ajax({
                    url: '/root/captcha',
                    type: 'POST',
                    success(res) {
                        that.captchaImg = res.data
                    },
                    error(res) {
                        that.$toast(res.message, 'error')
                    },
                })
            },
            // 登录
            submit() {
                let that = this
                that.$refs['form'].validate(valid => {
                    if (valid) {
                        let ajaxData = JSON.parse(JSON.stringify(that.form))
                        ajaxData.password = md5(ajaxData.password)
                        that.ajax({
                            url: '/root/login',
                            type: 'POST',
                            data: ajaxData,
                            beforeSend() {
                                that.loading = true
                            },
                            complete() {
                                that.loading = false
                            },
                            success() {
                                that.$message({
                                    message: '登录成功',
                                    type: 'success'
                                });
                                setTimeout(() => {
                                    location.href = location.origin
                                }, 500)
                            },
                            error(res) {
                                that.loadCaptcha()
                                that.form.captcha = ''
                                that.$message.error(res.message);
                            },
                        })
                    }
                });
            },
        }
    }
</script>

<style lang="less" scoped>
    .login {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #282C34;
        z-index: 2;

        >.bg-img {
            position: absolute;
            width: 850px;
            bottom: 0;
            left: 0;
            transform: translate(-100%, 100%);
            z-index: 1;
            transition: transform .8s;
        }

        >.bg-img-show {
            transform: translate(-160px, 273px);
        }
    }

    @keyframes gradientBG {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    .login-box {
        position: relative;
        width: 400px;
        padding: 80px 40px 30px 40px;
        border-radius: 5px;
        background-color: #ffffff;
        overflow: hidden;
        z-index: 1;
    }

    .submit {
        text-align: center;
        padding-top: 10px;
    }

    .logo {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(40px, -20px);
        width: 140px;
        opacity: 0.4;
    }

    .captcha {
        position: relative;
        width: calc(50% - 10px);
        height: 50px;
        bottom: 5px;
        display: inline-block;
        vertical-align: bottom;
        cursor: pointer;
        vertical-align: top;
        font-size: 0;
        margin-left: 10px;

        /deep/ .captcha-refresh {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            font-size: 14px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            background-color: rgba(0, 0, 0, 0.4);
            opacity: 0;
            transition: opacity 0.3s;
        }

        /deep/ &:hover>.captcha-refresh {
            opacity: 1;
        }
    }
</style>